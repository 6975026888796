import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import TabPanel from '../components/TabPanel';
import Tabs from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import styles from '../styles/Media.module.css';
import { styled } from '@mui/material/styles';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import useToggle from '../hooks/useToggle';
import YouTube from 'react-youtube';
import { useAppDispatch, useAppSelector } from '../store';
import { ShareFormInfoResponse } from '../store/post/api/interface';
import { IHomeParams } from '../enum';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Stack } from '@mui/system';
import moment from 'moment';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ConfirmationModel from '../components/modal/ConfirmationModel';
import Page from '../components/Page';
import { isMobile } from '../utils/getMobileOperatingSystem';
import { TopicObject } from '../store/groupMediaTopic/api/interface';
import { showSuccessMessage } from '../utils/utilMethods';
import Vimeo from '@u-wave/react-vimeo';
import { AppColors } from '../utils/utils';
import AddTopicIcon from '../assets/media/add_topic.svg';
import AddMediaIcon from '../assets/media/add_media.svg';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import playIcon from '../assets/play_circle.svg';
import { GroupMediaItem } from '../store/corporateReporting/api/interface';
import {
  deleteGroupPost,
  getAffiliateGroupMedia,
  toggleGroupShareStatus,
  updateActiveStatus,
} from '../store/AffiliateGroup/api';
import ViewGroupMediaPostModal from '../components/modal/ViewGroupMediaPostModal';
import AddAffiliateGroupMediaModal from '../components/modal/AddAffiliateGroupMediaModal';
import {
  mediaPostUpdated,
  postDeleted,
} from '../store/AffiliateGroup/AffiliateGroupSlice';
import { getTopicList } from '../store/groupMediaTopic/api';
import AddGroupMediaTopicModal from '../components/modal/AddGroupMediaTopicModal';
import { isNotEmpty } from '../utils/valid';
import EditAffiliateGroupMediaModal from '../components/modal/EditAffiliateGroupMediaModal';

const GalleryTab = styled(Tab)<TabProps>(({ theme }) => ({
  color: 'white',
  fontWeight: 'bold',
  minHeight: '50px',
  '&.Mui-selected': {
    backgroundColor: AppColors.primaryColor,
    color: AppColors.whiteColor,
    borderRadius: '10px',
  },
}));

const opts = {
  height: '100%',
  width: '100%',
  playerVars: {
    // Hide related videos after the video finishes
    rel: 0,
    // Hide the video annotations
    iv_load_policy: 3,
    // Hide the YouTube logo
    modestbranding: 1,
  },
};

const GroupMedia: React.FC = () => {
  const params = useParams<IHomeParams>();
  const [postTab, setPostTab] = useState(0);
  const matches = useMediaQuery('(max-width:745px)');
  const { toggle, onOpen, onClose } = useToggle();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state?.auth?.user?.id);
  const userInfo = useAppSelector((state) => state?.auth?.user);
  // const isProUser = checkIsProUser(userInfo);
  const isLoading = useAppSelector(
    (state) => state?.affiliateGroup?.isMediaLoading
  );
  const posts =
    useAppSelector((state) => state?.affiliateGroup.affiliateGroupMedia) ?? [];

  const topicData =
    useAppSelector((state) => state?.mediaTopic.data?.data) ?? [];
  const topicIsLoading = useAppSelector(
    (state) => state?.mediaTopic?.isLoading
  );
  const topicHasMore = useAppSelector((state) => state?.mediaTopic?.hasMore);
  const topicCurrentPage = useAppSelector(
    (state) => state?.mediaTopic?.currentPage
  );
  const [isPinedPostExpanded, setIsPinedPostExpanded] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState<number | undefined>(
    undefined
  );
  const [activePost, setActivePost] = useState<GroupMediaItem>();
  const [manuallyClicked, setManuallyClicked] = useState<boolean>(false);
  const [shareFormInfoResponse, setShareFormInfoResponse] =
    useState<ShareFormInfoResponse>();
  const observer = useRef<IntersectionObserver>();
  const [searchParams] = useSearchParams();

  const [editPost, setEditPost] = useState<GroupMediaItem | undefined>();
  const [deletePost, setDeletePost] = useState<GroupMediaItem | undefined>();

  const [changeStatusPost, setChangeStatusPost] = useState<
    GroupMediaItem | undefined
  >();
  const [toggleGroupSharePost, setToggleGroupSharePost] = useState<
    GroupMediaItem | undefined
  >();

  const [isShareBuyNowLink, setShareBuyNowLink] = useState<boolean>(false);

  const {
    toggle: toggleMediaForm,
    onOpen: onOpenMediaForm,
    onClose: onCloseMediaForm,
  } = useToggle();

  const {
    toggle: toggleChangeStatus,
    onOpen: onOpenChangeStatus,
    onClose: onCloseChangeStatus,
  } = useToggle();

  const {
    toggle: toggleToggleGroupShare,
    onOpen: onOpenToggleGroupShare,
    onClose: onCloseToggleGroupShare,
  } = useToggle();

  const {
    toggle: toggleAddTopic,
    onOpen: onOpenAddTopic,
    onClose: onCloseAddTopic,
  } = useToggle();

  const {
    toggle: toggleEditMediaForm,
    onOpen: onOpenEditMediaForm,
    onClose: onCloseEditMediaForm,
  } = useToggle();

  const {
    toggle: togglenDeleteMedia,
    onOpen: onOpenDeleteMedia,
    onClose: onCloseDeleteMedia,
  } = useToggle();

  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [totalSlide, setTotalSlide] = useState<number>(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [activeMenuIndex, setActiveMenuIndex] = React.useState<any>(0);
  const menuOpen = Boolean(anchorEl);
  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    idx: number
  ) => {
    setAnchorEl(event.currentTarget);
    setActiveMenuIndex(idx);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangeStatusMenu = (post: GroupMediaItem) => {
    setChangeStatusPost(post);
    onOpenChangeStatus();
    setAnchorEl(null);
  };

  const handleToggleGroupShareMenu = (post: GroupMediaItem) => {
    setToggleGroupSharePost(post);
    onOpenToggleGroupShare();
    setAnchorEl(null);
  };

  useEffect(() => {
    // if (userId && userInfo?.username == userInfo?.affiliate_group?.owner_name) {
    if (userId) {
      if (posts.length === 0) {
        dispatch(
          getAffiliateGroupMedia({
            groupId: userInfo?.affiliate_group?.id!,
            topic_id: selectedTopic,
          })
        );
      }
    }
  }, [userId]);

  useEffect(() => {
    dispatch(
      getTopicList({
        group_id: userInfo?.affiliate_group?.id!,
        page: topicCurrentPage,
      })
    );
  }, [topicCurrentPage]);

  useEffect(() => {
    // if (userId && userInfo?.username == userInfo?.affiliate_group?.owner_name) {
    if (userId) {
      dispatch(
        getAffiliateGroupMedia({
          groupId: userInfo?.affiliate_group?.id!,
          topic_id: selectedTopic,
        })
      );
    }
  }, [selectedTopic]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPostTab(newValue);
  };

  // const lastElementRef = useCallback(
  //   // (*)
  //   (node: HTMLDivElement | null) => {
  //     if (isLoading) return;
  //     if (observer.current) observer.current.disconnect();
  //     observer.current = new IntersectionObserver((entries) => {
  //       if (entries[0].isIntersecting && hasMore) {
  //         dispatch(incrementCurrentPage(currentPage + 1));
  //       }
  //     });
  //     if (node) observer.current.observe(node);
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [isLoading, hasMore]
  // );

  const handleActivePost = (post: GroupMediaItem) => {
    const sc = searchParams.get('sc');
    onOpen();
    setManuallyClicked(true);
    setActivePost(post);
  };

  const onChangeStatusConfirm = async () => {
    onCloseChangeStatus();
    if (changeStatusPost != undefined) {
      try {
        var result = await dispatch(
          updateActiveStatus({
            groupId: changeStatusPost.group_id ?? -1,
            mediaPostId: changeStatusPost.id,
            status: changeStatusPost.status == 1 ? 0 : 1,
          })
        ).unwrap();
        if (result.data.status == 'success') {
          dispatch(mediaPostUpdated(result.data.request));
          showSuccessMessage('Status has been changed successfully.');
          setChangeStatusPost(undefined);
        }
      } catch (error: any) {}
    }
  };

  const onToggleGroupShareConfirm = async () => {
    onCloseToggleGroupShare();
    if (toggleGroupSharePost != undefined) {
      try {
        var result = await dispatch(
          toggleGroupShareStatus({
            groupId: toggleGroupSharePost.group_id ?? -1,
            mediaPostId: toggleGroupSharePost.id,
          })
        ).unwrap();
        if (result.data.status == 'success') {
          dispatch(mediaPostUpdated(result.data.request));
          showSuccessMessage('Toggled successfully.');
          setToggleGroupSharePost(undefined);
        }
      } catch (error: any) {}
    }
  };

  const handleOnGroupTrainingMediaClicked = () => {
    navigate('/dashboard/group-training-media');
  };

  const handleEditMenu = (post: GroupMediaItem) => {
    setEditPost(post);
    onOpenEditMediaForm();
    setAnchorEl(null);
  };

  const handleDeleteMenu = (post: GroupMediaItem) => {
    setDeletePost(post);
    onOpenDeleteMedia();
    setAnchorEl(null);
  };

  const onDeleteMediaConfirm = async () => {
    onCloseDeleteMedia();
    if (deletePost != undefined) {
      try {
        var result = await dispatch(
          deleteGroupPost({ user_id: userId, postId: deletePost.id })
        ).unwrap();
        if (result.data.status == 'success') {
          dispatch(postDeleted(deletePost));
          showSuccessMessage('Group post deleted successfully.');
          setDeletePost(undefined);
        }
      } catch (error: any) {}
    }
  };

  return (
    <>
      {' '}
      <Page title='Group Media | KnoCard' showAppBar={true}>
        <Stack>
          <Box
            component={'img'}
            // className='cover-img'
            src={
              isNotEmpty(userInfo?.affiliate_group?.background_link)
                ? userInfo?.affiliate_group?.background_link
                : '/static/images/group_bg/knocard.jpg'
            }
            sx={{
              borderRadius: '12px',
              objectFit: 'cover',
              justifyContent: 'center',
              alignItems: 'center',
              height: '200px',
              width: '100%',
              mt: 2,
            }}
          />
          {
            <Box
              p={1}
              display='flex'
              flexDirection={{ xs: 'column', md: 'row' }}
              sx={{
                backgroundColor: '#FDFCFD',
              }}
            >
              <Button
                variant='outlined'
                sx={{
                  fontWeight: '500',
                  fontSize: '1.3rem',
                  textTransform: 'capitalize',
                  margin: '20px',
                  color: AppColors.whiteColor,
                  background: AppColors.primaryColor,
                  borderRadius: '12px',
                  minWidth: { xs: '50%', md: '20%' },
                  maxHeight: '70px',
                  ':hover': {
                    color: AppColors.primaryColor,
                  },
                }}
                onClick={handleOnGroupTrainingMediaClicked}
              >
                Go To Leaders Training
              </Button>

              <Grid
                container
                spacing={isMobile() ? 1 : 3}
                direction={'row'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                {[
                  { id: -1, title: '', link: '' } as TopicObject,
                  ...topicData,
                ].map((item, index) =>
                  index === 0 ? (
                    <>
                      {userInfo?.username ==
                        userInfo?.affiliate_group?.owner_name && (
                        <Grid item key={index} display={'flex'}>
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            textAlign={'center'}
                            p={1}
                            sx={{
                              cursor: 'pointer',
                            }}
                            onClick={() => onOpenAddTopic()}
                          >
                            <Box
                              component={'img'}
                              src={AddTopicIcon}
                              sx={{
                                height: '50px',
                                width: '50px',
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: '0.8rem',
                                color: AppColors.grayThreeColor,
                                mt: 1,
                              }}
                            >
                              Add Topic
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                      <Grid
                        item
                        // xs
                        key={index}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        onClick={() => setSelectedTopic(undefined)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <Stack
                          alignItems={'center'}
                          sx={{
                            background:
                              selectedTopic === undefined
                                ? AppColors.grayZeroColor
                                : 'unset',
                            p: 1,
                            borderRadius: '10px',
                          }}
                        >
                          <img
                            // component='img'
                            height='50px'
                            width='50px'
                            src={'/static/images/knocard.png'}
                            alt={'All'}
                            style={{
                              borderRadius: 100,
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: '0.8rem',
                              color: AppColors.grayThreeColor,
                              mt: 1,
                            }}
                          >
                            All
                          </Typography>
                        </Stack>
                      </Grid>
                    </>
                  ) : (
                    <Grid
                      item
                      // xs
                      key={index}
                      display={'flex'}
                      flexDirection={'column'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      onClick={() =>
                        setSelectedTopic(
                          selectedTopic !== topicData[index - 1].id
                            ? topicData[index - 1].id
                            : undefined
                        )
                      }
                      sx={{ cursor: 'pointer' }}
                    >
                      <Stack
                        alignItems={'center'}
                        sx={{
                          background:
                            selectedTopic === topicData[index - 1].id
                              ? AppColors.grayZeroColor
                              : 'unset',
                          p: 1,
                          borderRadius: '10px',
                        }}
                      >
                        <img
                          // component='img'
                          height='50px'
                          width='50px'
                          src={item.link}
                          alt={item.title}
                          style={{
                            borderRadius: 100,
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: '0.8rem',
                            color: AppColors.grayThreeColor,
                            mt: 1,
                          }}
                        >
                          {item.title}
                        </Typography>
                      </Stack>
                    </Grid>
                  )
                )}
              </Grid>
            </Box>
          }
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            width='100%'
            pt={2}

            //sx={{ backgroundColor: "#f1f2f6" }}
          >
            <Tabs
              value={postTab}
              onChange={handleChange}
              variant='fullWidth'
              sx={{
                width: '100%',
                borderRadius: '10px',
                backgroundColor: AppColors.whiteColor,
                pl: { xs: 1, md: 0 },
                pr: { xs: 1, md: 0 },
              }}
              TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
            >
              <GalleryTab
                icon={
                  <GridViewOutlinedIcon
                    sx={{
                      color:
                        postTab == 0
                          ? AppColors.whiteColor
                          : AppColors.blackColor,
                    }}
                  />
                }
              />
              <GalleryTab
                icon={
                  <FormatListBulletedIcon
                    sx={{
                      color:
                        postTab == 1
                          ? AppColors.whiteColor
                          : AppColors.blackColor,
                    }}
                  />
                }
              />
            </Tabs>
          </Box>

          <TabPanel value={postTab} index={0} p='18px 0 0 0'>
            <Grid className={styles.mediaContainerGrid}>
              {userInfo?.username == userInfo?.affiliate_group?.owner_name && (
                <Box
                  className={styles.mediaElementAdd}
                  sx={{ backgroundColor: AppColors.whiteColor }}
                  onClick={() => onOpenMediaForm()}
                >
                  <Box
                    component={'img'}
                    src={AddMediaIcon}
                    alt='addMedia'
                    sx={{ width: '40%' }}
                  />
                  <Typography
                    sx={{ color: AppColors.grayThreeColor, fontSize: '1rem' }}
                  >
                    Add Group Media
                  </Typography>
                </Box>
              )}

              {posts?.map((post, idx) => {
                return (
                  <Box
                    // ref={idx + 1 === posts?.length ? lastElementRef : undefined}
                    key={`post-${idx}`}
                    className={styles.mediaElement}
                    style={{
                      backgroundImage: `url(${
                        post?.thumb_link
                          ? post?.thumb_link
                          : post?.contents?.[0]?.type === 'pdf'
                          ? '/static/images/pdf.png'
                          : post?.contents?.[0]?.type === 'audio'
                          ? '/static/images/mp3.png'
                          : post?.contents?.[0]?.type === 'video'
                          ? post?.contents?.[0]?.thumbnail ?? ''
                          : post?.contents?.[0]?.link ?? ''
                      })`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      position: 'relative',
                      ...(!post?.thumb_link &&
                      post?.contents?.[0]?.type === 'pdf'
                        ? {
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                          }
                        : {}),
                      borderRadius: '10px',
                      objectFit: 'cover',
                    }}
                  >
                    <Box
                      sx={{ width: '100%', height: '100%' }}
                      onClick={() => handleActivePost(post)}
                    ></Box>

                    {post?.contents?.[0]?.type === 'video' && (
                      <Box
                        component={'img'}
                        src={playIcon}
                        sx={{
                          height: '30%',
                          width: '30%',
                          color: '#dfe2e4',
                          position: 'absolute',
                          top: '35%',
                          left: '35%',
                          fontSize: '4rem',
                        }}
                        onClick={() => handleActivePost(post)}
                      />
                    )}

                    {userInfo?.username ==
                      userInfo?.affiliate_group?.owner_name && (
                      <Box
                        sx={{
                          backgroundColor: '#000000ad',
                          borderRadius: '50%',
                          height: '40px',
                          width: '40px',
                          position: 'absolute',
                          top: '10px',
                          right: '10px',
                        }}
                      >
                        <IconButton
                          aria-label='more'
                          id='long-button'
                          aria-controls={
                            menuOpen && activeMenuIndex == idx
                              ? 'long-menu'
                              : undefined
                          }
                          aria-expanded={
                            menuOpen && activeMenuIndex == idx
                              ? 'true'
                              : undefined
                          }
                          aria-haspopup='true'
                          onClick={(event) => handleMenuClick(event, idx)}
                        >
                          <MoreVertIcon sx={{ color: 'white' }} />
                        </IconButton>
                        <Menu
                          id='long-menu'
                          MenuListProps={{
                            'aria-labelledby': 'long-button',
                          }}
                          anchorEl={anchorEl}
                          open={menuOpen && activeMenuIndex == idx}
                          onClose={handleMenuClose}
                          PaperProps={{
                            style: {
                              maxHeight: 48 * 4.5,
                              width: '30ch',
                            },
                          }}
                        >
                          <MenuItem
                            key={`edit-media-${idx}`}
                            onClick={() => handleEditMenu(post)}
                          >
                            Edit
                          </MenuItem>
                          <MenuItem
                            key={`change-status-${idx}`}
                            onClick={() => handleChangeStatusMenu(post)}
                          >
                            {post.status == 1 ? 'Deactivate' : 'Activate'}
                          </MenuItem>
                          <MenuItem
                            key={`toggle-group-share-${idx}`}
                            onClick={() => handleToggleGroupShareMenu(post)}
                          >
                            {post.is_included_group_share == 1
                              ? 'Disable Group Share'
                              : 'Enable Group Share'}
                          </MenuItem>
                          <MenuItem
                            key={`delete-media-${idx}`}
                            onClick={() => handleDeleteMenu(post)}
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                      </Box>
                    )}
                  </Box>
                );
              })}
              {posts?.length < 4 &&
                Array(3)
                  .fill(0)
                  .map((item, index) => {
                    return <Box key={`emptyitem-${index}`}></Box>;
                  })}
            </Grid>
            {isLoading && (
              <Box display='flex' justifyContent='center' mt={2}>
                <CircularProgress />
              </Box>
            )}
          </TabPanel>
          <TabPanel
            value={postTab}
            index={1}
            p='25px 0 0 0'
            // backgroundColor="#f1f2f6"
          >
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              gap={1}
            >
              {userInfo?.username == userInfo?.affiliate_group?.owner_name && (
                <Box
                  className={styles.mediaElementAdd}
                  sx={{
                    backgroundColor: AppColors.whiteColor,
                    width: '100%',
                    pt: 10,
                    pb: 10,
                  }}
                  onClick={() => onOpenMediaForm()}
                >
                  <Box
                    component={'img'}
                    src={AddMediaIcon}
                    alt='addMedia'
                    sx={{ width: '80px' }}
                  />
                  <Typography
                    sx={{ color: AppColors.grayThreeColor, fontSize: '1rem' }}
                  >
                    Add Group Media
                  </Typography>
                </Box>
              )}
              {posts?.map((post, idx) => {
                return (
                  <Card
                    // ref={idx + 1 === posts?.length ? lastElementRef : undefined}
                    key={idx}
                    sx={{
                      width: '100%',
                      boxShadow: 'unset !important',
                      p: 0,
                    }}
                    className='we-are-live-post-card'
                  >
                    <Stack direction='column' gap={1}>
                      <Box
                        display='flex'
                        alignItems='center'
                        height='60px'
                        sx={{ pl: 2, pr: 2, pt: 1 }}
                      >
                        <Box width='50px'>
                          <Avatar
                            alt='pic'
                            src={
                              userInfo?.profile_picture ??
                              '/static/images/placeholder_profile.png'
                            }
                          />
                        </Box>
                        <Box
                          display='flex'
                          flexDirection='column'
                          alignItems='flex-start'
                          justifyContent='center'
                          flex={1}
                        >
                          <Typography
                            variant='h6'
                            component='div'
                            color='black'
                            className='post-main-title'
                            sx={{ color: AppColors.blackColor }}
                          >
                            {userInfo?.name}
                          </Typography>
                          <Typography
                            variant='h6'
                            component='div'
                            color='primary'
                            fontSize='10px'
                            className='post-main-sub-title'
                            sx={{ color: AppColors.grayTwoColor }}
                          >
                            {userInfo?.occupation}
                          </Typography>
                        </Box>
                        {userInfo?.username ==
                          userInfo?.affiliate_group?.owner_name && (
                          <Box width='50px'>
                            <IconButton
                              aria-label='more'
                              id='long-button'
                              aria-controls={
                                menuOpen && activeMenuIndex == idx
                                  ? 'long-menu'
                                  : undefined
                              }
                              aria-expanded={
                                menuOpen && activeMenuIndex == idx
                                  ? 'true'
                                  : undefined
                              }
                              aria-haspopup='true'
                              onClick={(event) => handleMenuClick(event, idx)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id='long-menu'
                              MenuListProps={{
                                'aria-labelledby': 'long-button',
                              }}
                              anchorEl={anchorEl}
                              open={menuOpen && activeMenuIndex == idx}
                              onClose={handleMenuClose}
                              PaperProps={{
                                style: {
                                  maxHeight: 48 * 4.5,
                                  width: '30ch',
                                },
                              }}
                            >
                              <MenuItem
                                key={`edit-media-${idx}`}
                                onClick={() => handleEditMenu(post)}
                              >
                                Edit
                              </MenuItem>
                              <MenuItem
                                key={`change-status-${idx}`}
                                onClick={() => handleChangeStatusMenu(post)}
                              >
                                {post.status == 1 ? 'Deactivate' : 'Activate'}
                              </MenuItem>
                              <MenuItem
                                key={`toggle-group-share-${idx}`}
                                onClick={() => handleToggleGroupShareMenu(post)}
                              >
                                {post.is_included_group_share == 1
                                  ? 'Disable Group Share'
                                  : 'Enable Group Share'}
                                Toggle Group Share
                              </MenuItem>
                              <MenuItem
                                key={`delete-media-${idx}`}
                                onClick={() => handleDeleteMenu(post)}
                              >
                                Delete
                              </MenuItem>
                            </Menu>
                          </Box>
                        )}
                      </Box>
                      <Splide
                        key={`media-splide-${post?.id}`}
                        aria-label='Images'
                        options={{
                          perPage: 1,
                          fixedHeight: 390,
                        }}
                        onMove={(event) => {
                          setActiveSlide(event.index);
                        }}
                        onReady={(event) => {
                          setTotalSlide(event.length);
                        }}
                      >
                        {post?.contents?.map((content, sIdx) => {
                          return content?.type === 'image' ? (
                            <SplideSlide key={content.id}>
                              <Box
                                component={'img'}
                                src={content?.link}
                                alt={content?.filename}
                                style={{ maxHeight: '50vh' }}
                                height='100%'
                                width='100%'
                                className='object-fit-contain'
                              />
                            </SplideSlide>
                          ) : content?.type === 'pdf' ? (
                            <SplideSlide key={content.id}>
                              {activeSlide == sIdx && (
                                <iframe
                                  key={`iframe-${content.id}`}
                                  src={`https://drive.google.com/viewerng/viewer?pid=explorer&efh=false&a=v&chrome=false&embedded=true&url=${content?.link}`}
                                  itemType='application/pdf'
                                  width='100%'
                                  height='100%'
                                  style={{ overflowY: 'scroll' }}
                                ></iframe>
                              )}
                            </SplideSlide>
                          ) : content?.type === 'audio' ? (
                            <SplideSlide key={content.id}>
                              {activeSlide == sIdx && (
                                <audio
                                  controls
                                  playsInline
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    maxHeight: '50vh',
                                  }}
                                  // className='object-fit-contain'
                                >
                                  <source src={content?.link} />
                                  Your browser does not support the audio tag.
                                </audio>
                              )}
                            </SplideSlide>
                          ) : (
                            <SplideSlide key={content.id}>
                              {content?.platform === 'youtube' ? (
                                <YouTube
                                  videoId={content?.link}
                                  opts={opts}
                                  className='object-fit-contain'
                                  style={{
                                    height: '100%',
                                    width: '100%',
                                    maxHeight: '50vh',
                                  }}
                                />
                              ) : content?.platform === 'vimeo' ? (
                                <Vimeo
                                  video={content?.link!}
                                  className='object-fit-contain vimeo-video-contain'
                                  height={'100%'}
                                  width={'100%'}
                                  style={{
                                    height: '100%',
                                    width: '100%',
                                    maxHeight: '50vh',
                                  }}
                                  // onPlay={() => handleOnplayVideo(content.id)}
                                />
                              ) : (
                                <video
                                  controls
                                  width='100%'
                                  height='100%'
                                  style={{ maxHeight: '50vh' }}
                                  className='object-fit-contain'
                                  poster={content?.thumbnail ?? ''}
                                >
                                  <source
                                    src={content?.link}
                                    type='video/mp4'
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              )}
                            </SplideSlide>
                          );
                        })}
                      </Splide>
                      {post?.contents && post?.contents?.length > 0 && (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            pl: 2,
                            pr: 2,
                          }}
                        >
                          <Button
                            variant='contained'
                            sx={{
                              backgroundColor: AppColors.lightYellowColor,
                              color: AppColors.blackColor,
                              textTransform: 'none',
                              fontSize: '0.8rem',
                              fontWeight: 400,
                              zIndex: 1000,
                              lineHeight: 'normal',
                              mt: 0.3,
                            }}
                          >
                            {`${activeSlide + 1}`} of {totalSlide ?? 0}
                          </Button>
                        </Box>
                      )}
                      <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ pl: 2, pr: 2 }}
                      >
                        {/* <Stack
                          direction='row'
                          color='grey'
                          alignItems='center'
                          gap={1}
                        >
                          <RemoveRedEyeOutlinedIcon color='inherit' />
                          <Typography
                            variant='body2'
                            component='div'
                            color='inherit'
                            sx={{ color: AppColors.grayTwoColor }}
                          >
                            {post?.views_count} Views
                          </Typography>
                        </Stack> */}
                        <Typography
                          variant='body2'
                          component='div'
                          sx={{ color: AppColors.grayTwoColor }}
                        >
                          {moment(post?.created_at).format('DD MMMM YYYY')}
                        </Typography>
                      </Box>
                      <Box
                        display='flex'
                        alignItems='flex-start'
                        justifyContent='flex-start'
                        sx={{ pl: 2, pr: 2, pb: 1 }}
                      >
                        <Typography
                          variant='body1'
                          component='div'
                          sx={{ color: AppColors.grayThreeColor }}
                        >
                          {post?.description}
                        </Typography>
                      </Box>
                    </Stack>
                  </Card>
                );
              })}
              {isLoading && (
                <Box display='flex' justifyContent='center' mt={2}>
                  <CircularProgress />
                </Box>
              )}
            </Box>
          </TabPanel>
          {/* <BlockOverlay isBlock={!isProUser} /> */}
        </Stack>
      </Page>
      <ViewGroupMediaPostModal
        toggle={toggle}
        onClose={onClose}
        activePost={activePost}
        shareFormInfoResponse={shareFormInfoResponse}
        setShareFormInfoResponse={setShareFormInfoResponse}
        manuallyClicked={manuallyClicked}
        isShareBuyNowLink={isShareBuyNowLink}
      />
      {editPost &&
        userInfo?.username == userInfo?.affiliate_group?.owner_name &&
        toggleEditMediaForm && (
          <EditAffiliateGroupMediaModal
            toggle={toggleEditMediaForm}
            onClose={onCloseEditMediaForm}
            postObject={editPost}
          />
        )}
      {toggleMediaForm && (
        <AddAffiliateGroupMediaModal
          groupId={userInfo?.affiliate_group?.id!}
          onClose={onCloseMediaForm}
          toggle={toggleMediaForm}
        />
      )}
      <ConfirmationModel
        cancelButtonText='No'
        confirmButtonText='Yes'
        title='Change Status'
        message='Are you sure! Do you want to change status of this post?'
        onConfirm={onChangeStatusConfirm}
        onClose={onCloseChangeStatus}
        toggle={toggleChangeStatus}
      />
      <ConfirmationModel
        cancelButtonText='No'
        confirmButtonText='Yes'
        title={
          toggleGroupSharePost?.is_included_group_share == 1
            ? 'Disable Group Share'
            : 'Enable Group Share'
        }
        message={
          toggleGroupSharePost?.is_included_group_share == 1
            ? 'Are you sure! Do you want to disable group share?'
            : 'Are you sure! Do you want to enable group share?'
        }
        onConfirm={onToggleGroupShareConfirm}
        onClose={onCloseToggleGroupShare}
        toggle={toggleToggleGroupShare}
      />
      <ConfirmationModel
        cancelButtonText='Cancel'
        confirmButtonText='Delete'
        title='Delete Group Media'
        message='Are you sure! Do you want to delete this media?'
        onConfirm={onDeleteMediaConfirm}
        onClose={onCloseDeleteMedia}
        toggle={togglenDeleteMedia}
      />
      <AddGroupMediaTopicModal
        groupId={userInfo?.affiliate_group?.id!}
        onClose={onCloseAddTopic}
        toggle={toggleAddTopic}
      />
    </>
  );
};

export default GroupMedia;
