export const config = {
  //Production
  BASE_URL: 'https://knocard.com',
  VBC_URL: 'https://knocard.app',
  KNOCARD_URL: 'https://knocard.com/',
  AFFILIATE_DASHBOARD_URL: 'https://knocard.com/affiliate/auth-with-token',
  PAYPAL_PRO_MONTHLY_PLAN_ID: 'P-8HM29164AK524803YMTM6GQA',
  PAYPAL_PRO_YEARLY_PLAN_ID: 'P-2BR42193VD677212GMTOFVRI',
  PAYPAL_CLIENT_ID:
    'AVqFOvArPgckzQqEt0JYWdH7r_KVPlKzzHM7DkRbCMqCCPFgvIrzx1832SdMpjcWmRslhCgDI6mffF4Q',
  H_CAPTCHA_SITEKEY: 'd43e028c-b0ce-4d8a-8a52-c56424d663bb',
  SECRET_KEY: 'mbyvTJqGS1m',
  CHANNEL: 'channel',
  GROUP: 'group',
  MESSAGES_COLLECTION: 'messages',

  // Staging
  // BASE_URL: 'https://staging.knocard.com',
  // VBC_URL: 'https://staging.knocard.app',
  // KNOCARD_URL: 'https://staging.knocard.com/',
  // AFFILIATE_DASHBOARD_URL:
  //   'https://staging.knocard.com/affiliate/auth-with-token',
  // PAYPAL_PRO_MONTHLY_PLAN_ID: 'P-6H187344B0616444XMTOFYBY',
  // PAYPAL_PRO_YEARLY_PLAN_ID: 'P-4T466386U3238550NMTOFXHI',
  // PAYPAL_CLIENT_ID:
  //   'ARDJUpiapBcNRjeZ_Pf-Neb7kkvoaV2f6kCJbhZaJUzb2eB3YIwGt1YzgfHmGbLv3EIMHeNKBtcghSzn',
  // H_CAPTCHA_SITEKEY: 'd43e028c-b0ce-4d8a-8a52-c56424d663bb',
  // SECRET_KEY: 'jcR2bsMqNwG',
  // CHANNEL: 'dev-channel',
  // GROUP: 'dev-group',
  // MESSAGES_COLLECTION: 'messages',

  // local
  // BASE_URL: 'http://localhost:8000',
  // VBC_URL: 'http://localhost:3000',
  // KNOCARD_URL: 'http://localhost:8000/',
  // AFFILIATE_DASHBOARD_URL: 'http://192.168.1.4:8000/affiliate/auth-with-token',
  // PAYPAL_PRO_MONTHLY_PLAN_ID: 'P-6H187344B0616444XMTOFYBY',
  // PAYPAL_PRO_YEARLY_PLAN_ID: 'P-4T466386U3238550NMTOFXHI',
  // PAYPAL_CLIENT_ID:
  //   'ARDJUpiapBcNRjeZ_Pf-Neb7kkvoaV2f6kCJbhZaJUzb2eB3YIwGt1YzgfHmGbLv3EIMHeNKBtcghSzn',
  // H_CAPTCHA_SITEKEY: '20000000-ffff-ffff-ffff-000000000002',
  // SECRET_KEY: 'oQOMcLy1xGT',
  // CHANNEL: 'dev-channel',
  // GROUP: 'dev-group',
  // MESSAGES_COLLECTION: 'messages',

  //In MB
  CHAT_VIDEO_MAX_SIZE: 10,
  CHAT_IMAGE_MAX_SIZE: 2,

  BEARER_TOKEN: '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',
  PLAY_STORE_URL:
    'https://play.google.com/store/apps/details?id=com.vbc.knocard.app',
  APP_STORE_URL: 'https://apps.apple.com/us/app/knocard/id1495206208',

  AUTH_KEY: 'authkey',
  SLECTED_PLAN_KEY: 'selectedPlan',
  SLECTED_PLAN_IS_ANNUALLY_KEY: 'isSelectedPlanAnnually',
  IS_KNOCARD_LINK_SELECTED_KEY: 'isKnoCardLinkSelected',
  IS_KNOCARD_SPOTLIGHT_SELECTED_KEY: 'isKnoCardSpotlightSelected',
  IS_KNOCARD_LEADERS_CORNER_SELECTED_KEY: 'isKnoCardLeadersCornerSelected',
  IS_PURCHASE_SELECTED_PLAN_KEY: 'isPurchaseSelectedPlan',
  SIGNUP_REDIRECT_KEY: 'srk',
  INFO: 'info',
  REFER_KEY: 'ru',
  NEW_TO_KNOCARD_KEY: 'newToKnoCard',
  PROSPECT_KEY: 'prospect',
  REVIEW_KEY: 'review',
  USER_PROFILE_PAGE_KEY: 'userProfilePage',
  REQUIRED_SUBSCRIPTION_KEY: 'requiredSubscription',
  OPEN_DASHBOARD_KEY: 'openDashboard',
  GROUP_NETWORK_ID_KEY: 'groupNetworkId',
  IS_SOCIAL_REGISTER: 'isSocialRegister',
  DOWNLOAD_APP_DONT_SHOW_AGAIN: 'downloadAppDontShowAgain',
  DOWNLOAD_APP_DONT_SHOW_AGAIN_AFTER_SIGN_UP:
    'downloadAppDontShowAgainAfterSignUp',

  SHARE_KEY: 'share',
  //
  // H_CAPTCHA_SITEKEY: '10000000-ffff-ffff-ffff-000000000001', // For localhost testing

  FREE: 'FREE',
  PRO_BASIC: 'PRO_BASIC',
  PRO_PRO: 'PRO_PRO',
  PRO_PLUS: 'PRO_PLUS',
  PRO_1: 'PRO_1',
  PRO_FREE_TRIAL: 'PRO_FREE_TRIAL',
  PRO: 'PRO',
  KNOCARD_SOCIAL_CONNECT: 'KNOCARD_SOCIAL_CONNECT',
  KNOCARD_LINK: 'KNOCARD_LINK',
  KNOCARD_SPOTLIGHT: 'KNOCARD_SPOTLIGHT',
  KNOCARD_LEADERS_CORNER: 'KNOCARD_LEADERS_CORNER',

  // stripe or paypal
  PAYMENT_GATEWAY: 'stripe',

  FACEBOOK_URL: 'https://facebook.com/',
  INSTAGRAM_URL: 'https://www.instagram.com',
  LINKEDIN_URL: 'https://www.linkedin.com/',
};

export const NAVBAR = {
  DASHBOARD_NAVBAR_WIDTH: 280,
  DASHBOARD_COLLAPSE_NAVBAR_WIDTH: 88,
  DASHBOARD_ROOT_NAVBAR_ITEM_HEIGHT: 48,
  DASHBOARD_NAVBAR_SUB_ITEM_HEIGHT: 40,
};


export const PaypalInitialOptions: any = {
  clientId: config.PAYPAL_CLIENT_ID,
  currency: "USD",
  intent: "capture",
  vault: true
}



export const CSV_NEW_PROSPECTS_HEADER = [
  {
    label: 'Name',
    key: 'full_name',
    fieldName: 'full_name'
  },
  {
    label: 'Phone Number',
    key: 'phone_number',
    fieldName: 'phone_number',
  },
  {
    label: 'Email',
    key: 'email',
    fieldName: 'email',
  },
  {
    label: 'Where i met you',
    key: 'where_i_met_you',
    fieldName: 'where_i_met_you',
  },
  {
    label: 'Occupation',
    key: 'occupation',
    fieldName: 'occupation',
  },
  {
    label: 'Note',
    key: 'notes',
    fieldName: 'notes',
  },
  {
    label: 'Created At',
    key: 'created_at',
    fieldName: 'created_at',
  },
];


export const tutorialTabs = [
  { title: "Setup", code: "setup" },
  { title: "Features", code: "features" },
  { title: "Affiliate", code: "affiliate" },
  { title: "Training", code: "training" },
];


export const courseVideoTypeTabs = [
  { title: "Introduction", code: "introduction" },
  { title: "Features", code: "features" },
  { title: "User Case", code: "user_case" },
  { title: "Q & A", code: "q_&_a" },
  { title: "Objection Training", code: "objection_training" },
];


export const trainingTabs = [
  { title: "Setup", code: "setup" },
  { title: "Features", code: "features" },
  { title: "Affiliate", code: "affiliate" },
  { title: "Training", code: "training" },
];

export const affiliateLandingPageVideoTabs = [
  { title: "Top Features", code: "top_features" },
  { title: "Commonly asked questions", code: "commonly_asked_questions" },
  { title: "CEO chat", code: "ceo_chat" }
];


export const AppColors = {
  liteRedColor: '#FFCCD1',
  redColor: '#FF4155',
  whiteColor: '#FFFFFF',
  backgroundColor: '#FAFAFA',
  primaryColor: '#007CB4',
  blackColor: '#303336',
  grayZeroColor: '#EEF1F7',
  grayOneColor: '#AEB2B7',
  grayTwoColor: '#93989F',
  grayThreeColor: '#60656C',
  litePrimaryColor: '#E5F2FF',
  strokeColor: '#E8ECF4',
  yellowColor: '#FEC703',
  orangeColor: '#F7922C',
  secondryColor: '#00B0E5',
  greenColor: '#00DB7D',
  tableHeader: '#007cb41f',
  lightYellowColor: '#FFDE59',
  lightYellowColor2: '#E8F13A',
};

export const ThumbnailTextColors = ['#ffffff', '#000000'];