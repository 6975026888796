import {
  Stack,
  Typography,
  Box,
  CircularProgress,
  Grid,
  useMediaQuery,
  Card,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  CardContent,
  Button,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Tooltip,
  TextField,
} from '@mui/material';
import Page from '../components/Page';
import { AppDispatch, useAppSelector } from '../store';
import { useDispatch } from 'react-redux';
import React, { useCallback, FC, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import useToggle from '../hooks/useToggle';
import useWindowSize from '../utils/useWindowSize';
import { Link, useNavigate } from 'react-router-dom';
import ConfirmationModel from '../components/modal/ConfirmationModel';


import {
  addCompanyFeedShare,
  createUpdateUserCompanyFeedReaction,
  deleteSocialFeed,
  getCompanyPinSocialFeeds,
  getSocialFeeds,
  pinSocialFeed,
  unpinSocialFeed,
} from '../store/social/api';
import { CompanyFeed } from '../store/social/api/interface';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import YouTube from 'react-youtube';
import moment from 'moment';
import ReactReadMoreReadLess from '../components/ReactReadMoreReadLess';
import { FaRegComment, FaRegHeart } from 'react-icons/fa';
import IosShareIcon from '@mui/icons-material/IosShare';
import {
  socialFeedDeleted,
  socialFeedPinned,
  socialFeedUnpinned,
  socialFeedUpdated,
} from '../store/social/socialSlice';
import { encryptString } from '../utils/url';
import BusinessForm from '../components/BusinessForm';
import CommunityUserFilter from '../components/SearchMember';
import SocialDailog from '../components/modal/SocialDailog';
import { isNotEmpty } from '../utils/valid';
import ViewCommunityModal from '../components/modal/ViewCommunityModal';
import { setCommunityFeed } from '../store/communityFeed/communityFeedSlice';
import { SOCIAL_LIKE_ICONS } from '../constant';
import CompanyFeedCommentsModal from '../components/modal/CompanyFeedCommentsModal';
import { SharePostType, SocialShareType } from '../enum';
import CompanyFeedShareLinkModal from '../components/modal/CompanyFeedShareLinkModal';
import { followUserRequest, unFollowUserRequest } from '../store/communityUsers/api';
import ReportSocialPostDailog from '../components/modal/ReportSocialPostModel';
import NewToKnoCardModal from '../components/modal/NewToKnoCardModal';
import { getItem, setItem } from '../utils/localStorage';
import { AppColors, config } from '../utils/utils';
import { checkIsProUser } from '../utils/checkPro';
import { shareSocialFeedMessage } from "../store/social/socialSlice"
import { inputTextFieldStyle, openLinkOnNewTab, shareSocialPostMessage, showErrorMessage, showSuccessMessage } from '../utils/utilMethods';
import Vimeo from '@u-wave/react-vimeo';
import AddSocialPostIcon from '../assets/social/add_social.svg';
import HeartIcon from '../assets/heart.svg';
import EyeIcon from '../assets/eye.svg';
import ShareIcon from '../assets/share.svg';
import CommentIcon from '../assets/comment.svg';
import SearchMemberView from '../components/SearchMemberView';

const opts = {
  height: '100%',
  width: '100%',
  playerVars: {
    // Hide related videos after the video finishes
    rel: 0,
    // Hide the video annotations
    iv_load_policy: 3,
    // Hide the YouTube logo
    modestbranding: 1,
  },
};

const BusinessSocial = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const userInfo = useAppSelector((state) => state?.auth?.user);
  const socialPosts = useAppSelector((state) => state?.socialPost.data) ?? [];
  const pinnedSocialPosts = useAppSelector((state) => state?.socialPost.pinFeeds) ?? [];
  const isLoading = useAppSelector((state) => state?.socialPost?.isLoading);
  const hasMore = useAppSelector((state) => state?.socialPost?.isLoadMore);
  const currentPage = useAppSelector((state) => state?.socialPost?.page);
  const pinnedPostCurrentPage = useAppSelector(
    (state) => state?.socialPost?.pinFeedPage
  );
  const [openEditForm, setOpenEditForm] = useState(false);
  const observer = useRef<IntersectionObserver>();


  const {
    toggle: togglenNewToKnoCard,
    onOpen: onOpenNewToKnoCard,
    onClose: onCloseNewToKnoCard,
  } = useToggle();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    if (userInfo?.new_to_knocard == 1 && getItem(config.NEW_TO_KNOCARD_KEY) != userInfo.id) {
      setItem(config.NEW_TO_KNOCARD_KEY, userInfo.id);
      onOpenNewToKnoCard();
    }
  }, []);

  const [deleteSocialPost, setDeleteSocialPost] = useState<CompanyFeed | undefined>();
  const [reportSocialPost, setReportSocialPost] = useState<CompanyFeed | undefined>();
  const [copySocialPost, setCopySocialPost] = useState<CompanyFeed | undefined>();

  const {
    toggle: togglenDeleteSocialPost,
    onOpen: onOpenDeleteSocialPost,
    onClose: onCloseDeleteSocialPost,
  } = useToggle();


  const {
    toggle: togglenReportSocialPost,
    onOpen: onOpenReportSocialPost,
    onClose: onCloseReportSocialPost,
  } = useToggle();

  const [communityPost, setCommunityPost] = useState<CompanyFeed | null>(null);
  const openSocialDailog = (feed: CompanyFeed) => {

    setCommunityPost(feed);
    onOpenSocialDailog();
  };

  const {
    toggle: toggleSocialDailog,
    onOpen: onOpenSocialDailog,
    onClose: onCloseSocialDailog,
  } = useToggle();

  const {
    toggle: toggleCommunity,
    onOpen: onOpenCommunity,
    onClose: onCloseCommunity,
  } = useToggle();

  const {
    toggle: toggleFeedComment,
    onOpen: onOpenFeedComment,
    onClose: onCloseFeedComment,
  } = useToggle();

  const {
    toggle: toggleFeedLinkShare,
    onOpen: onOpenFeedLinkShare,
    onClose: onCloseFeedLinkShare,
  } = useToggle();


  const openFeedCommentDailog = (feed: CompanyFeed) => {

    setCommunityPost(feed);
    onOpenFeedComment();
  };

  useEffect(() => {
    if (!toggleSocialDailog && !toggleFeedLinkShare) {
      setCommunityPost(null);
    }
  }, [toggleSocialDailog, toggleFeedLinkShare]);

  const lastElementRef = useCallback(
    // (*)
    (node: HTMLDivElement | null) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          handleLoadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, hasMore]
  );

  const handleLoadMore = () => {
    if (!isLoading) {
      dispatch(
        getSocialFeeds({
          page: currentPage,
          user_id: userInfo?.id,
          //companyId: userInfo?.company?.id,
        })
      );
    }
  };

  const handleLoadMorePinPost = () => {
    if (!isLoading) {
      dispatch(
        getCompanyPinSocialFeeds({
          page: pinnedPostCurrentPage,
          user_id: userInfo?.id,
          companyId: userInfo?.company?.id,
        })
      );
    }
  };

  useEffect(() => {
    if (socialPosts.length == 0) {
      handleLoadMore();
    }

    if (pinnedSocialPosts.length == 0) {
      handleLoadMorePinPost();
    }
  }, []);

  const matches = useMediaQuery('(max-width:745px)');
  const windowSize = useWindowSize();
  const [cardWidth, setCardWidth] = useState(300);

  useEffect(() => {
    const newWidth = matches ? windowSize.width > 350 ? windowSize.width * 0.90 : windowSize.width * 0.80 : windowSize.width > 890 ? 745 : windowSize.width * 0.80;

    setCardWidth(newWidth);
  }, [windowSize]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activeMenuIndex, setActiveMenuIndex] = useState<any>(0);

  const menuOpen = Boolean(anchorEl);
  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    idx: number
  ) => {
    setAnchorEl(event.currentTarget);
    setActiveMenuIndex(idx);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handlePinPostMenu = async (companyFeedItem: CompanyFeed) => {
    setAnchorEl(null);

    try {
      var result = await dispatch(
        pinSocialFeed({ feedId: companyFeedItem.id })
      ).unwrap();
      if (result.data.status === 'success') {
        dispatch(socialFeedPinned(companyFeedItem));
        // dispatch(socialFeedDeleted(companyFeedItem));
        showSuccessMessage('Post pinned successfully.');
      } else if (isNotEmpty(result.data.message)) {
        showErrorMessage(`${result.data.message}`);
      }
    } catch (error: any) { }
  };

  const handleUnpinPostMenu = async (companyFeedItem: CompanyFeed) => {
    setAnchorEl(null);

    try {
      var result = await dispatch(
        unpinSocialFeed({ feedId: companyFeedItem.id })
      ).unwrap();
      if (result.data.status === 'success') {
        dispatch(socialFeedUnpinned(companyFeedItem));
        // dispatch(socialFeedCreated(companyFeedItem));
        showSuccessMessage('Post unpinned successfully.');
      } else if (isNotEmpty(result.data.message)) {
        showErrorMessage(`${result.data.message}`);
      }
    } catch (error: any) { }
  };

  const handleEditMenu = (companyFeedItem: CompanyFeed) => {
    navigate(
      `/dashboard/edit-business-social/${encryptString(
        `${companyFeedItem.id ?? ''}`
      )}`
    );
    setAnchorEl(null);
  };

  const handleDeleteMenu = (companyFeedItem: CompanyFeed) => {
    setDeleteSocialPost(companyFeedItem);
    onOpenDeleteSocialPost();
    setAnchorEl(null);
  };
  const handleReportMenu = (companyFeedItem: CompanyFeed) => {
    setReportSocialPost(companyFeedItem);
    onOpenReportSocialPost();
    setAnchorEl(null);
  };
  const handleCopySocialFeedMenu = (companyFeedItem: CompanyFeed) => {
    navigate(
      `/dashboard/add-business-social/${encryptString(
        `${companyFeedItem.id ?? ''}`
      )}`
    );
    setAnchorEl(null);
  };
  const onDeleteSocialPostConfirm = async () => {
    onCloseDeleteSocialPost();
    if (deleteSocialPost != undefined) {
      try {
        var result = await dispatch(
          deleteSocialFeed({ feedId: deleteSocialPost.id })
        ).unwrap();
        if (result.data.status.code == 'success') {
          dispatch(socialFeedDeleted(deleteSocialPost));
          showSuccessMessage('Business post deleted successfully.');
          setDeleteSocialPost(undefined);
        }
      } catch (error: any) { }
    }
  };

  const handleAddSocialPost = () => {
    navigate('/dashboard/add-business-social');
  };

  const handleOpenEditCoverForm = () => {
    setOpenEditForm(!openEditForm);
  };


  const [likeAnchorEl, setLikeAnchorEl] = useState<null | HTMLElement>(null);
  const [activeLikeMenuIndex, setActiveLikeMenuIndex] = useState<any>(0);

  const openLikeMenu = Boolean(likeAnchorEl);
  const handleLikeMenuClick = (event: React.MouseEvent<HTMLElement>, idx: number) => {
    setLikeAnchorEl(event.currentTarget);
    setActiveLikeMenuIndex(idx);
  };
  const handleLikeMenuClose = async (likeCompanyFeed?: CompanyFeed, reaction_id?: number) => {
    setLikeAnchorEl(null);
    if (likeCompanyFeed) {
      let result = await dispatch(createUpdateUserCompanyFeedReaction({ companyFeedId: likeCompanyFeed.id, reactionId: reaction_id })).unwrap();

      if (result.data.status === 'success') {
        let updatedLikeCompanyFeed = { ...likeCompanyFeed };
        if (result.data.data !== undefined) {
          updatedLikeCompanyFeed.reaction = result.data.data;
          updatedLikeCompanyFeed.reactions_count += 1;
        } else {
          updatedLikeCompanyFeed.reaction = undefined;
          if (updatedLikeCompanyFeed.reactions_count > 0) {
            updatedLikeCompanyFeed.reactions_count -= 1;
          }
        }
        dispatch(socialFeedUpdated(updatedLikeCompanyFeed));
      }
    }
  };



  const [shareAnchorEl, setShareAnchorEl] = useState<null | HTMLElement>(null);
  const [activeShareMenuIndex, setActiveShareMenuIndex] = useState<any>(0);

  const openShareMenu = Boolean(shareAnchorEl);
  const handleShareMenuClick = (event: React.MouseEvent<HTMLElement>, idx: number) => {
    setShareAnchorEl(event.currentTarget);
    setActiveShareMenuIndex(idx);
  };

  const handleShareMenuClose = async (shareCompanyFeed?: CompanyFeed, socialShareType?: string) => {
    setShareAnchorEl(null);

    if (shareCompanyFeed != null) {
      switch (socialShareType) {
        case SocialShareType.link:
          setCommunityPost(shareCompanyFeed);
          onOpenFeedLinkShare();
          dispatch(addCompanyFeedShare({
            postById: shareCompanyFeed?.company.owner?.id ?? 0,
            companyFeedId: shareCompanyFeed.id,
            actionType: socialShareType,
            targetId: undefined
          })).unwrap().then((result) => {

            if (result.status == 200 && result.data.status == "success") {
              if (shareCompanyFeed.id == result.data.data.company_feed_id) {
                dispatch(socialFeedUpdated({ ...shareCompanyFeed, shares_count: shareCompanyFeed.shares_count + 1 }))
              }
            }
          });
          break;
        case SocialShareType.community:
          dispatch(shareSocialFeedMessage({ socialPost: shareCompanyFeed, message: shareSocialPostMessage(shareCompanyFeed.company?.owner?.username ?? "", shareCompanyFeed, userInfo) }));
          navigate(`/dashboard/messaging?${config.SHARE_KEY}=${encryptString(SharePostType.social_post)}`);
          break;
        case SocialShareType.share_faceook:
          navigator.clipboard.writeText(shareCompanyFeed?.description ?? "");
          showSuccessMessage("Copied the social post.");
          openLinkOnNewTab(config.FACEBOOK_URL);
          break;
        case SocialShareType.share_instagram:
          navigator.clipboard.writeText(shareCompanyFeed?.description ?? "");
          showSuccessMessage("Copied the social post.");
          openLinkOnNewTab(config.INSTAGRAM_URL);
          break;
        case SocialShareType.share_linkedin:
          navigator.clipboard.writeText(shareCompanyFeed?.description ?? "");
          showSuccessMessage("Copied the social post.");
          openLinkOnNewTab(config.LINKEDIN_URL);
          break;

      }
    }
  };

  const handleFollowUnFollow = (post: CompanyFeed) => {
    if (post.company?.owner?.id != undefined) {
      if (post.company?.owner?.follow_by_auth_user != undefined) {
        dispatch(unFollowUserRequest({ user_id: post.company?.owner?.id })).unwrap().then((result) => {
          if (result.status == 200 && result.data.status == "success") {
            dispatch(
              getSocialFeeds({
                page: 1,
                user_id: userInfo?.id,
                //companyId: userInfo?.company?.id,
              })
            );
          }
        });
      } else {
        dispatch(followUserRequest({ user_id: post.company?.owner?.id })).unwrap().then((result) => {
          if (result.status == 200 && result.data.status == "success") {
            dispatch(
              getSocialFeeds({
                page: 1,
                user_id: userInfo?.id,
                //companyId: userInfo?.company?.id,
              })
            );
          }
        });
      }
    }
  };

  return (
    <>
      <Page title='Business Social | KnoCard' showAppBar={true}>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          gap={3}
          sx={{ marginTop: 5, mb: 1 }}
        >
          {/* <Grid
            xs={12}
            md={12}
            container
            direction='row'
            justifyContent={'center'}
            alignItems='center'
            sx={{
              width: '100%',
            }}
          >
          <Fab
              variant='extended'
              sx={{
                backgroundColor: '#ffffff',
                color: '#088ac6',
                marginRight: 3,
                marginBottom: { sm: 0, xs: 2 },
                zIndex: 'unset',
              }}
              onClick={handleOpenEditCoverForm}
            >
              {' '}
              <Box
                component={'img'}
                src={'/static/images/ic_edit.png'}
                sx={{ mr: 1, height: '25px' }}
              />
              Edit Cover
            </Fab>
            <Card key={'add-social-post'} sx={{ padding: 0, width: '80%' }}>
              <CommunityUserFilter />
            </Card>  

          </Grid> */}

          {pinnedSocialPosts && pinnedSocialPosts.length > 0 && (
            <Box sx={{ width: '95%' }}><ImageList cols={3} gap={15}>
              {pinnedSocialPosts.map((postItem: CompanyFeed, index: number) => (
                <ImageListItem
                  key={postItem.id}
                  className='preferred-partner-image-border'
                  sx={{
                    border: `2px solid ${AppColors.strokeColor}`
                  }}
                >
                  <Box
                    component={'img'}
                    onClick={async () => {
                      dispatch(setCommunityFeed(postItem));
                      onOpenCommunity();
                    }}
                    src={
                      isNotEmpty(postItem?.contents)
                        ? postItem?.contents[0]?.type === 'image'
                          ? postItem?.contents[0]?.link
                          : postItem?.contents[0]?.thumbnail ??
                          '/static/images/placeholder_profile.png'
                        : '/static/images/placeholder_profile.png'
                    }
                    alt={postItem?.contents[0]?.filename ?? ''}
                    loading='lazy'
                    sx={{
                      objectFit: 'cover',
                      height: '100%',
                      width: '100%',
                      borderRadius: '8px',
                      maxHeight: { sm: '250px', xs: "150px" }
                    }}
                  // className='preferred-partner-image-border'
                  />
                  <ImageListItemBar
                    title={postItem.title}
                    actionIcon={
                      <Tooltip title={'Unpin'}>
                        <IconButton
                          aria-label='close'
                          onClick={() => handleUnpinPostMenu(postItem)}
                          sx={{ color: 'white' }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    }
                    sx={{
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px'
                    }}
                  />
                </ImageListItem>
              ))}
            </ImageList></Box>
          )}

          {/* <Grid container direction='row' spacing={1} mt={1}>
            {pinnedSocialPosts?.map((postItem, index) => {
              return (
                <Grid item key={postItem.id} xs={3} md={3}>
                  <IconButton
                    aria-label='close'
                    onClick={() => handleUnpinPostMenu(postItem)}
                    sx={{
                      position: 'absolute',
                      right: 0,
                      zIndex: 100000,
                      color: 'black',
                      alignSelf: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <img
                    onClick={() => {
                      dispatch(setCommunityFeed(postItem));
                      onOpenCommunity();
                    }}
                    src={
                      isNotEmpty(postItem?.contents)
                        ? postItem?.contents[0]?.type === 'image'
                          ? postItem?.contents[0]?.link
                          : postItem?.contents[0]?.thumbnail ??
                            '/static/images/placeholder_profile.png'
                        : '/static/images/placeholder_profile.png'
                    }
                    alt={postItem?.contents[0]?.filename ?? ''}
                    height='100%'
                    width='100%'
                    className='preferred-partner-image-border'
                  />
                </Grid>
              );
            })}
          </Grid> */}
          <Grid container spacing={3} justifyContent={'center'} sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}>
            {/* {openEditForm && (
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <BusinessForm onCancel={handleOpenEditCoverForm} />
              </Grid>
            )} */}

            <Grid item xs={12} sm={12} md={8}>
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                gap={1}
              >
                <Box
                  key={'add-social-post'}
                  sx={{
                    width: '100%',
                    pl: 2,
                    pr: 2,
                    mb: 3
                  }}
                >
                  <Stack direction='column'>
                    <Box display='flex' alignItems='center' height='60px'>
                      <Box>
                        <Avatar
                          alt='pic'
                          src={
                            userInfo?.profile_picture ??
                            '/static/images/placeholder_profile.png'
                          }
                          sx={{
                            height: '50px',
                            width: '50px',
                          }}
                        />
                      </Box>
                      <Box
                        display='flex'
                        flexDirection='column'
                        alignItems='flex-start'
                        justifyContent='center'
                        flex={1}
                        sx={{
                          ml: 1.3,
                          mr: 1.3,
                          backgroundColor: AppColors.grayZeroColor,
                          border: `1px solid ${AppColors.strokeColor}`,
                          p: 2,
                          borderRadius: '10px'
                        }}
                        onClick={handleAddSocialPost}
                      >
                        <Typography
                          sx={{ color: AppColors.grayThreeColor }}
                        >
                          What's New?
                        </Typography>


                      </Box>
                      <Box
                        component={'img'}
                        src={AddSocialPostIcon}
                        onClick={handleAddSocialPost}
                      />
                    </Box>
                  </Stack>
                </Box>

                {socialPosts?.map((post, idx) => {
                  return (
                    <Card
                      ref={
                        idx + 1 === socialPosts?.length
                          ? lastElementRef
                          : undefined
                      }
                      key={idx}
                      sx={{
                        width: "100%",
                        boxShadow: 'unset !important',
                        p: 0
                      }}
                      className='we-are-live-post-card'
                    >
                      <Stack direction='column' gap={1}>
                        <Box display='flex' alignItems='center' height='60px'
                          sx={{ pl: 1.4, pt: 1 }}>
                          <Box width='50px'>
                            <Avatar
                              alt='pic'
                              src={
                                post.company?.owner?.profile_picture ??
                                '/static/images/placeholder_profile.png'
                              }
                            />
                          </Box>
                          <Box
                            display='flex'
                            flexDirection='column'
                            alignItems='flex-start'
                            justifyContent='center'
                            flex={1}
                          >
                            <Stack
                              flexDirection={'row'}
                            >
                              <Typography
                                variant='h6'
                                component='div'
                                color='black'
                                className='post-main-title'
                                sx={{ color: AppColors.blackColor }}
                              >
                                {post.company?.owner?.name ?? ''}
                              </Typography>
                              {(userInfo?.id != post.company?.owner?.id) && <Typography

                                component='div'
                                color='black'
                                sx={{
                                  ml: 1,
                                  color: AppColors.primaryColor,
                                  fontSize: '1.0rem !important',
                                  lineHeight: '18px',
                                  cursor: 'pointer'
                                }}
                                onClick={() => handleFollowUnFollow(post)}
                              >
                                {post.company?.owner?.follow_by_auth_user ? 'Following' : 'Follow'}
                              </Typography>}
                            </Stack>
                            <Typography
                              variant='h6'
                              component='div'
                              fontSize='10px'
                              className='post-main-sub-title'
                              sx={{ color: AppColors.grayTwoColor }}
                            >
                              {post.company?.owner?.occupation ?? ''}

                              <Box component={'span'}
                                sx={{ marginLeft: '10px', color: AppColors.grayTwoColor }}
                              >
                                {moment(post?.created_at).format(
                                  'MMM DD, YYYY'
                                )}
                              </Box>
                            </Typography>
                          </Box>

                          <Box >
                            <IconButton
                              aria-label='more'
                              id='long-button'
                              aria-controls={
                                menuOpen && activeMenuIndex == idx
                                  ? 'long-menu'
                                  : undefined
                              }
                              aria-expanded={
                                menuOpen && activeMenuIndex == idx
                                  ? 'true'
                                  : undefined
                              }
                              aria-haspopup='true'
                              onClick={(event) => handleMenuClick(event, idx)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id='long-menu'
                              MenuListProps={{
                                'aria-labelledby': 'long-button',
                              }}
                              anchorEl={anchorEl}
                              open={menuOpen && activeMenuIndex == idx}
                              onClose={handleMenuClose}
                              PaperProps={{
                                style: {
                                  maxHeight: 48 * 4.5,
                                  width: '20ch',
                                },
                              }}
                            >
                              {(userInfo?.company.id == post.company_id) ? <><MenuItem
                                key={`pin-business-social-${idx}`}
                                onClick={() => handlePinPostMenu(post)}
                              >
                                Pin
                              </MenuItem>
                                <MenuItem
                                  key={`edit-business-social-${idx}`}
                                  onClick={() => handleEditMenu(post)}
                                >
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  key={`delete-${idx}`}
                                  onClick={() => handleDeleteMenu(post)}
                                >
                                  Delete
                                </MenuItem></> : <><MenuItem
                                  key={`report-${idx}`}
                                  sx={{ color: "red" }}
                                  onClick={() => handleReportMenu(post)}
                                >
                                  Report
                                </MenuItem>
                                <MenuItem
                                  key={`report-${idx}`}
                                  sx={{ color: "black" }}
                                  onClick={() => handleCopySocialFeedMenu(post)}
                                >
                                  Copy post to<br/>personal feed
                                </MenuItem></>}
                            </Menu>
                          </Box>
                        </Box>

                        <RenderCompanyMediaFeed companyFeed={post} />
                        <CardContent
                          sx={{
                            pl: 2.5, pr: 2,
                            pt: 0,
                            '&:last-child': { pb: '16px' },
                          }}
                        >
                          <Grid
                            container
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                          >
                            <Grid item>
                              <Typography
                                variant='body1'
                                gutterBottom
                                sx={{ color: AppColors.blackColor, fontSize: "medium", fontWeight: '500' }}
                              >
                                {post.title}
                              </Typography>
                              <Typography
                                variant='body2'
                                className='link'
                                sx={{ whiteSpace: 'pre-line', color: AppColors.grayTwoColor }}
                              >
                                <ReactReadMoreReadLess
                                  charLimit={200}
                                  readMoreText={'Read more'}
                                  readLessText={'Read less'}
                                  readMoreClassName='read-more-less-more'
                                  readLessClassName='read-more-less-less'
                                >
                                  {post.description}
                                </ReactReadMoreReadLess>
                              </Typography>
                            </Grid>
                          </Grid>

                          <Box
                            display='flex'
                            mt={2}
                          >
                            <Stack
                              direction='row'
                              color='grey'
                              alignItems='center'
                              gap={1}
                              sx={{ mr: 3 }}
                            >
                              <Box
                                aria-controls={openLikeMenu ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openLikeMenu ? 'true' : undefined}
                                onClick={(e) => post.reaction ? handleLikeMenuClose(post, undefined) : handleLikeMenuClick(e, idx)}
                              >
                                {(post.reaction && post.reaction.reaction_id >= 0 && post.reaction.reaction_id <= 5) ? <Box component={'img'} src={SOCIAL_LIKE_ICONS[post.reaction.reaction_id]} sx={{
                                  height: '1.5rem',

                                }}></Box> : <Box
                                  component={'img'}
                                  src={HeartIcon}
                                  style={{
                                    height: '1.5rem',
                                    width: '1.5rem',
                                  }}
                                />}</Box>

                              <Menu
                                anchorEl={likeAnchorEl}
                                open={openLikeMenu && activeLikeMenuIndex == idx}
                                onClose={() => handleLikeMenuClose()}
                                MenuListProps={{
                                  'aria-labelledby': 'basic-button',
                                }}

                              >
                                <MenuItem onClick={() => handleLikeMenuClose(post, 0)}><Box component={'img'} src={SOCIAL_LIKE_ICONS[0]} sx={{
                                  height: '1.5rem',

                                }}></Box></MenuItem>
                                <MenuItem onClick={() => handleLikeMenuClose(post, 1)}><Box component={'img'} src={SOCIAL_LIKE_ICONS[1]} sx={{
                                  height: '1.5rem',

                                }}></Box></MenuItem>
                                <MenuItem onClick={() => handleLikeMenuClose(post, 2)}><Box component={'img'} src={SOCIAL_LIKE_ICONS[2]} sx={{
                                  height: '1.5rem',

                                }}></Box></MenuItem>
                                <MenuItem onClick={() => handleLikeMenuClose(post, 3)}><Box component={'img'} src={SOCIAL_LIKE_ICONS[3]} sx={{
                                  height: '1.5rem',

                                }}></Box></MenuItem>
                                <MenuItem onClick={() => handleLikeMenuClose(post, 4)}><Box component={'img'} src={SOCIAL_LIKE_ICONS[4]} sx={{

                                  height: '1.5rem',
                                }}></Box></MenuItem>
                                <MenuItem onClick={() => handleLikeMenuClose(post, 5)}><Box component={'img'} src={SOCIAL_LIKE_ICONS[5]} sx={{
                                  height: '1.5rem',
                                }}></Box></MenuItem>
                              </Menu>

                              <Typography variant='body2' component='div' sx={{ color: AppColors.grayTwoColor }}>
                                {post?.reactions_count ?? 0}
                              </Typography>
                            </Stack>

                            <Stack
                              direction='row'
                              color='grey'
                              alignItems='center'
                              gap={1}
                              sx={{ mr: 3 }}
                            >
                              <Box
                                component={'img'}
                                src={CommentIcon}
                                onClick={() => openFeedCommentDailog(post)}
                                sx={{
                                  height: '1.5rem',
                                  width: '1.5rem',
                                }}
                              />

                              <Typography variant='body2' component='div' sx={{ color: AppColors.grayTwoColor }}>
                                {post?.comments_count ?? 0}
                              </Typography>
                            </Stack>
                            <Stack
                              direction='row'
                              color='grey'
                              alignItems='center'
                              gap={1}
                              sx={{ mr: 3 }}
                            >
                              <Box
                                aria-controls={openLikeMenu ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openLikeMenu ? 'true' : undefined}
                                onClick={(e) => handleShareMenuClick(e, idx)}
                              >
                                <Box
                                  component={'img'}
                                  src={ShareIcon}
                                  sx={{
                                    height: '1.5rem',
                                    width: '1.5rem',
                                  }}
                                /></Box>
                              <Menu
                                anchorEl={shareAnchorEl}
                                open={openShareMenu && activeShareMenuIndex == idx}
                                onClose={() => handleShareMenuClose()}
                                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                              >
                                <MenuItem onClick={() => handleShareMenuClose(post, SocialShareType.link)}>Share Link</MenuItem>
                                {checkIsProUser(userInfo) && (<MenuItem onClick={() => handleShareMenuClose(post, SocialShareType.community)}>Share with Community</MenuItem>)}
                                <MenuItem onClick={() => handleShareMenuClose(post, SocialShareType.share_faceook)}>Copy to Facebook</MenuItem>
                                <MenuItem onClick={() => handleShareMenuClose(post, SocialShareType.share_instagram)}>Copy to Instagram</MenuItem>
                                <MenuItem onClick={() => handleShareMenuClose(post, SocialShareType.share_linkedin)}>Copy to LinkedIn</MenuItem>
                              </Menu>
                              <Typography variant='body2' component='div' sx={{ color: AppColors.grayTwoColor }}>
                                {post?.shares_count ?? 0}
                              </Typography>
                            </Stack>
                            <Stack
                              direction='row'
                              color='grey'
                              alignItems='end'
                              justifyContent={'flex-end'}
                              gap={1}

                              sx={{ width: '100%' }}
                            >

                              <Box
                                component={'img'}
                                src={EyeIcon}
                                style={{
                                  height: '1.5rem',
                                  width: '1.5rem'
                                }}
                              />

                              <Typography
                                variant='body2'
                                component='div'
                                sx={{ color: AppColors.grayTwoColor }}
                              >
                                {post?.views_count ?? 0}
                              </Typography>
                            </Stack>
                          </Box>
                        </CardContent>
                      </Stack>
                    </Card>
                  );
                })}
              </Box>
              {isLoading && (
                <Box display='flex' justifyContent='center' mt={2}>
                  <CircularProgress />
                </Box>
              )
              }
            </Grid>
            <Grid item xs={12} sm={12} md={4} sx={{ pl: { xs: "16px !important", md: 0 }, pr: { xs: "16px !important", md: "0px !important" }, ml: { xs: 3, md: 0 } }} >
              <SearchMemberView />
            </Grid>
          </Grid >

        </Box >
      </Page >
      <ConfirmationModel
        cancelButtonText='Cancel'
        confirmButtonText='Delete'
        title='Delete social post'
        message='Are you sure! Do you want to delete this business post?'
        onConfirm={onDeleteSocialPostConfirm}
        onClose={onCloseDeleteSocialPost}
        toggle={togglenDeleteSocialPost}
      />


      {
        reportSocialPost && (
          <ReportSocialPostDailog
            onClose={onCloseReportSocialPost}
            reportSocialPost={reportSocialPost}
            toggle={togglenReportSocialPost}
          />
        )
      }

      {
        communityPost && (
          <SocialDailog
            onClose={onCloseSocialDailog}
            userName={userInfo?.username ?? ''}
            communityPostId={communityPost?.id ?? 0}
            toggle={toggleSocialDailog}
            deepLink={communityPost?.deep_link}
          />
        )
      }

      <ViewCommunityModal
        toggle={toggleCommunity}
        onClose={onCloseCommunity}
        redirectToContact={false}
      />

      {
        communityPost && <CompanyFeedCommentsModal
          toggle={toggleFeedComment}
          onClose={onCloseFeedComment}
          companyFeed={communityPost} />
      }

      {
        communityPost && <CompanyFeedShareLinkModal
          toggle={toggleFeedLinkShare}
          onClose={onCloseFeedLinkShare}
          companyFeed={communityPost} />
      }
      <NewToKnoCardModal
        toggle={togglenNewToKnoCard}
        onClose={onCloseNewToKnoCard}
      />
    </>
  );
};

const RenderCompanyMediaFeed: FC<{ companyFeed: CompanyFeed }> = ({
  companyFeed,
}) => {
  const [activeSlide, setActiveSlide] = useState<number>(0)
  const [totalSlide, setTotalSlide] = useState<number>(0)

  if (companyFeed?.contents?.length > 0) {
    return (
      <><Splide
        aria-label='Images'
        options={{
          perPage: 1,
          //fixedHeight: 390,
        }}
        onMove={(event) => {
          setActiveSlide(event.index);
        }}
        onReady={(event) => {
          setTotalSlide(event.length)
        }}
      >
        {companyFeed?.contents?.map((content) => {
          return content?.type === 'image' ? (
            <SplideSlide key={content.id}>
              <img
                src={content?.link}
                alt={content?.filename ?? ''}
                height='100%'
                width='100%'
                style={{ maxHeight: '50vh' }}
                className='object-fit-contain'
              />
            </SplideSlide>
          ) : (
            <SplideSlide key={content.id}>
              {content?.platform === 'youtube' ? (
                <YouTube
                  videoId={content?.link}
                  opts={opts}
                  className='object-fit-contain'
                  style={{
                    height: '100%',
                    width: '100%',
                    maxHeight: '50vh'
                  }}
                // onPlay={() => handleOnplayVideo(content.id)}
                />
              ) : content?.platform === "vimeo" ? (
                <Vimeo
                  video={content?.link}
                  className="object-fit-contain vimeo-video-contain"
                  height={"100%"}
                  width={"100%"}
                  style={{
                    height: "100%",
                    width: "100%",
                    maxHeight: '50vh'
                  }}
                // onPlay={() => handleOnplayVideo(content.id)}
                />
              ) : (
                <video
                  controls
                  width='100%'
                  height='100%'
                  className='object-fit-contain'
                  poster={content?.thumbnail ?? ''}
                  style={{ maxHeight: '50vh' }}
                //onPlay={() => handleOnplayVideo(content.id)}
                >
                  <source
                    src={content?.link}
                    type='video/mp4'
                  />
                  Your browser does not support the video tag.
                </video>
              )}
            </SplideSlide>
          );
        })}

      </Splide>

        {companyFeed?.contents && companyFeed?.contents?.length > 0 && <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button
            variant='contained'
            sx={{
              // backgroundColor: '#088ac6',
              backgroundColor: AppColors.lightYellowColor,
              color: AppColors.blackColor,
              textTransform: 'none',
              fontSize: "1rem",
              zIndex: 1000,
              lineHeight: 'normal',
              mt: 0.3,
              mr: 1
            }}
          >

            {`${activeSlide + 1}`} of {totalSlide ?? 0}
          </Button>
        </Box>}
      </>
    );
  }

  return <></>;
};

export default BusinessSocial;
