export type STATUS_TYPE = 'idle' | 'pending' | 'succeeded' | 'failed';
export type IHomeParams = {
  username: string;
  tab?: string;
  postId?: string;
  form_code?: string;
};

export type INotFoundParams = {
  username: string;
};

export type IPreferredPartnerParams = {
  ppid: string;
};

export type IBusinessSocialParams = {
  bsid: string;
  cpbsid: string;
};

export type IUserLandingPageParams = {
  username: string;
};



export type ResourceType = 'USER' | 'KNOCARD' | 'MESSAGE' | 'GROUP' | 'COMPANY';

export enum BackgroundType {
  solidBackground = 'solid',
  gradientBackground = 'gradient',
  patternBackground = 'pattern',
  uploadBackground = 'upload',
  assetBackground = 'asset',
}


export enum MediaType {
  image = 'image',
  video = 'video',
  pdf = 'pdf',
  audio = 'audio',
}

export enum PlatformType {
  vimeo = "vimeo",
  youtube = 'youtube',
  local = 'local',
  asset = 'asset'
}

export enum YouTubeThumnailQualityType {
  low = 'Low',
  medium = 'medium',
  high = 'High',
  maximum = 'Maximum'
}


export enum SocialShareType {
  link = 'link',
  community = 'community',
  knocard_contact = 'knocard_contact',
  new_contact = 'new_contact',
  share_faceook = 'share_faceook',
  share_instagram = 'share_instagram',
  share_linkedin = 'share_linkedin'
}


export enum SharePostType {
  social_post = 'social_post',
  media_post = 'media_type'
}

export enum NotificationType {
  community = 'community',
  group = 'group',
}


export enum PaymentGateway {
  stripe = 'stripe',
  paypal = 'paypal',
}

export enum EngageNoteType {
  CONTACTED = 'contacted',
  FOLLOW_UP = 'follow_up',
  NOT_AT_THIS_TIME = 'not_at_this_time'
}

export enum EngageActivityType {

  ACTIVE_LEADS = 'active_leads',
  NO_ACTIVITY = 'no_activity'

}


export enum EngageActivityFilter {
  TODAY = 'today',
  WEEK = 'week',
  MONTH = 'month'
}



export enum EngageInterestLevel {

  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
  NO_INTEREST = 'no_interest'
}

export enum EngageLeadType {
  NEW_LEAD = 'new_lead',
  NEW_PROSPECT = 'new_prospect'

}


export enum PiplineCalendarEventType {
  FOLLOWUP = 'followup',
  TASK = 'task'

}


export enum ContactFormSourceType {
  QR_FORM = 'qr_form',
  WHAT_IS_KNOCARD = 'what_is_knocard',
  REJECTION_FREE = 'rejection_free'
}




export enum KnoCardLinkRequestStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  REVOKED = 'revoked'

}

